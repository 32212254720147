<template>
    <div style="position:relative; height:100vh">
        <iframe :src="url_data" frameborder="0" allowfullscreen
            style="position:relative;top:0;left:0;width:100%;height:100%;"></iframe>
    </div>
</template>
<script>
import commonFunction from "../../mixin/commonFunction";
export default {
    name: "view_my_reports",
    mixins: [commonFunction],
    mounted() {
        document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
        this.report_id = this.$route.params.url;
        document.body.classList.add('viewreporthidden');
        this.getSingleReport(this.report_id);
    },
    data() {
        return {
            report_id: null,
            url_data: null,
        };
    },
    methods: {
        getSingleReport(id) {
            var apiUrl;
            if (this.$router.currentRoute.name == 'view_employee_reports_prev') {
                apiUrl = "/api/get-prev-report/";
            } else {
                apiUrl = "/api/get-client-prev-report/";
            }
            var _this = this;
            let config = {
                method: "get",
                url: process.env.VUE_APP_API_URL + apiUrl + id,
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
                },
            };
            _this
                .axios(config)
                .then(({
                    data
                }) => {
                    var decodedJson = _this.decodeAPIResponse(data.data);
                    _this.url_data = decodedJson.summary_report;
                })
                .catch(({
                    response
                }) => {
                    _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                    _this.$router.push({
                        name: "404",
                    });
                    _this.$toast.error(response.data.error, {
                        position: "top-right",
                        duration: 5000,
                    });

                    /* _this.$toast.error("Please view a valid report.", {
                    position: "top-right",
                    duration: 5000,
                    });*/

                });
        },
    }
};
</script>